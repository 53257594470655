var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// Lib
import React from "react";
import { format, parse } from "date-fns";
import { unescapeString } from "../lib/unescapeString";
var LatestReview = /** @class */ (function (_super) {
    __extends(LatestReview, _super);
    function LatestReview() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            collapseOpen: false
        };
        _this.toggleCollapse = function (event) {
            event.preventDefault();
            _this.setState({ collapseOpen: !_this.state.collapseOpen });
        };
        _this.getCollapseClass = function () {
            var classes = ["collapse"];
            if (_this.state.collapseOpen)
                classes.push('collapse--open');
            return classes.join(' ');
        };
        _this.getDate = function () {
            var _a;
            if (typeof _this.props.reviewDate === 'undefined' || !_this.props.reviewDate.length)
                return React.createElement(React.Fragment, null);
            var dateObj = parse("".concat((_a = _this.props.reviewDate) !== null && _a !== void 0 ? _a : '19700101'), 'yyyyMMdd', new Date());
            var dateStr = format(dateObj, "dd.MM.yy");
            return React.createElement("span", { className: "venue__date" }, dateStr);
        };
        _this.render = function () {
            var collapseClass = _this.getCollapseClass();
            var date = _this.getDate();
            return (React.createElement("div", { className: collapseClass },
                React.createElement("button", { className: "collapse__button", type: "button", onClick: _this.toggleCollapse },
                    React.createElement("span", null, _this.state.collapseOpen ? "Close" : "Open")),
                React.createElement("h4", { className: "collapse__title" },
                    "\"",
                    unescapeString(_this.props.reviewTitle),
                    "\""),
                React.createElement("p", null,
                    unescapeString(_this.props.reviewContent),
                    "..."),
                React.createElement("div", { className: "actions" },
                    date,
                    React.createElement("a", { href: _this.props.venueURL, className: "link-btn" }, "Read more"))));
        };
        return _this;
    }
    return LatestReview;
}(React.PureComponent));
export default LatestReview;
